<template>
  <!-- 收款统计 -->
  <div class="home">
    <div class="searchNav">
      <!-- <el-button
        class="button"
        icon="el-icon-document"
        type="primary"
        style="margin-left: 0"
        :loading="addloading"
        @click="downloadFile"
        >收款统计收款统计收款统计收款统计收款统计</el-button
      > -->

      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
      </div>
    </div>
    <div class="searchNav">
      <div class="searchParams" style="justify-content: flex-start">
        <el-date-picker
          v-model="daterange"
          type="daterange"
          style="width: 250px; margin-left: 10px"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeTime"
        >
        </el-date-picker>
        <el-select
          class="input1"
          v-model="optionsKey"
          placeholder="请选择搜索类型"
          @change="selectChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input
          style="margin-left: 10px"
          class="input1"
          v-model="queryParam.CustomerName"
          :placeholder="placeholder"
          @keydown.enter.native="Getdata"
        ></el-input>
        <el-button class="button" @click="Getdata" type="primary">查询</el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      :max-height="height"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="cCusName" :label="label" width="800">
      </el-table-column>
      <el-table-column prop="ReceiveSum" label="收款金额" width="800">
        <template slot-scope="scope">
          {{ toThousands(scope.row.ReceiveSum)  }}
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex">
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="margin-top: 8px; padding: 0; flex: 1"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
      <el-row
        :gutter="20"
        style="width: 450px; text-align: right; font-weight: bold;padding:10px 0"
      >
        <el-col :span="23" >
          <div style="">总计收款金额: {{toThousands(totalReceiveSum) }}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { toThousands } from "../../utils/Tools";
import MyBreadcrumb from '../../components/myBreadcrumb.vue';
export default {
  name: "Home",
  components: {
    radioGroup,
    MyBreadcrumb,
    // waybillForm
  },
  data() {
    return {
      label:'客户',
      // 日期
      daterange:[],
      // 默认下拉选项的值
      optionsKey:'客户',
      placeholder: "请输入客户名称进行查询",
      // 金额格式化工具
      toThousands,
      // 搜索类型下拉数据
      options: [{
        value: '0',
        label: '客户'
      },
      {
        value: '1',
        label: '业务员'
      }],
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      // 收款统计列表数据
      tableData: [],
      // 查询列表参数
      queryParam: {
        CompanyId: "",
        StartTime: "",
        EndTime: "",
        CustomerName: "",
        Type:0
      },
      loading: false,
      // 总金额
      totalReceiveSum:0
    };
  },
  mounted() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 初始化
    toGetList(){
      this.loading=true
      // 获取收款统计列表数据
      this.getDataList()
      // 获取收款总金额统计数据
      this.GetCollectionAllStatistics();
    },
    // 搜索下拉改变
    selectChange(e) {
      console.log('选择的是',e);
      this.queryParam.Type=e
      if(e==0){
          this.label='客户'
          this.placeholder='请输入客户进行查询'
        }else{
          this.label = '业务员'
          this.placeholder='请输入业务员进行查询'
        }
      this.toGetList()
    },
    // 时间选择
    changeTime(e) {
      console.log(e);
      
      if(e){
        this.queryParam.StartTime = e[0];
        this.queryParam.EndTime = e[1];
      }else{
        this.queryParam.StartTime = ''
        this.queryParam.EndTime = ''
      }
    
      // 获取收款统计列表数据
      this.getDataList()
      // 获取收款总金额统计数据
      this.GetCollectionAllStatistics();
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectRows = rows.map((item) => item.evidenceId);
    },
    radioComplete(e) {
      console.log(e);
      if (e) {
        this.queryParam.CompanyId = e;
        // 获取收款统计列表数据
        this.getDataList();
        // 获取收款总金额统计数据
        this.GetCollectionAllStatistics();
      }
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
      this.GetCollectionAllStatistics();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },
    Getdata() {
      this.loading=true
      this.pagination.current=1
      this.tableData=[]
      this.getDataList();
      // this.GetCollectionAllStatistics()
    },

     // 获取列表收款总金额统计接口
     GetCollectionAllStatistics() {
      this.$http
        .post("/BO/CustomerInfo/GetCollecLinqtionAllStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log("获取最终的收款统计DIDDIDIDIDIID", resJson);
            if(resJson.Data == null){
              this.totalReceiveSum = 0
            }else{
              this.totalReceiveSum = resJson.Data.ReceiveSum;
            }
            
          } else {
            this.loading = false;
          }
        });
    },

    // 获取收款统计列表数据
    getDataList() {
      this.loading = true;
      let usr = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(usr.UserPhone);
      this.$http
        .post("BO/CustomerInfo/GetCollecLinqtionStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
.input1 {
  width: 200px;
  margin-left: 10px;
}
.button{
  background-color: #3362ec;
  border: #3362ec;
}

.button:hover {
  background-color: #3362ec;
}
</style>
